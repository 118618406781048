export default interface FeatureFlags {
    /*
     * Turns on the batch publication feature for the admins
     */
    allowBatchPublications?: boolean;

    allowPDFsInPublicationUpload?: boolean;

    /**
     * Disable all calls to Hermes. Use when Hermes is down to give users limited access to LiNK.
     */
    bypassHermes?: boolean;

    extractTablesFromPublicationImport?: boolean;

    /**
     * Flag for toggling I18N features
     */
    i18N?: boolean;
    /**
     * Flag for including enhanced content in search results
     */
    includeEnhancedContentInSearch?: boolean;

    internalizeEula?: boolean;

    refreshOnPageChange?: boolean;

    prependLabelToSectionTitle?: boolean;

    showAllPublicationsPage?: boolean;

    /* Hides the LiNK privacy banner and instead shows the OneTrust privacy policy banner. */

    useOneTrustPrivacyPolicyBanner?: boolean;

    /**
     * Show unhandled XML tags with some default styling in book view as an indication
     * that we need to address this XML tag.
     */
    showUnhandledXml?: boolean;

    /** Show the FAQ panel with demo video links */
    showVideoDemoFaq?: boolean;

    /** Use AzureB2C for SSO session */
    useAzureB2CForSSO?: boolean;

    useAzureGoLiveBanner?: boolean;

    useAzurePostGoLiveBanner?: boolean;

    /** Use the change summary feature. */
    useChangeSummaries?: boolean;

    /**
     * Flag to enable the new ErrorBoundary around BookViewLayout
     */
    useErrorBoundary?: boolean;

    useExpandableTableOfContents?: boolean;

    /**
     * Flag to enable viewing legacy pdf publications and other material
     */
    useDocumentViewer?: boolean;

    /**
     * Enable the signout flow when logged in as a different user than what is on a group invitation
     */
    useGroupInvitationSignoutFlow?: boolean;

    /**
     * Use HermesAPI for creating/updating/getting teams info
     */
    useHermesApiForTeams?: boolean;

    usePutItForwardForTopics?: boolean;

    /**
     * Render the Terms and Conditions page assuming the content is stored as markdown, not HTML
     */
    useMarkdownForTermsAndConditions?: boolean;

    /**
     * Flag used for enabling features that are related to offline such as network infomation, etc.
     */
    useOfflineFeatures?: boolean;

    /**
     * Flag used for enabling Pendo analytics.
     */
    usePendo?: boolean;

    /**
     * Flag to enable structured data on the plans page.
     */
    usePlansPageStructuredData?: boolean;

    /**
     * Flag to enable structured data on the publication preview page.
     */
    usePublicationPreviewPageStructuredData?: boolean;

    /**
     * Flag to enable rendering reduction in bookview.
     */
    useReducedRendersInBookview?: boolean;

    /**
     * Flag that enables simple tables(perfect grid 1 cell blocks with 1 set of headers) to look like cards on mobile view
     */
    useSimpleTableCards?: boolean;

    /**
     * Flag that enables using the simple style class for tables
     */
    useSimpleTableStyle?: boolean;

    /**
     * Flag to enable MsalInstance.ssoSilent(). There is sometimes an issue with SSO when going to
     * free access pages. This flag is used to quickly disable ssoSilent().
     */
    useSsoSilent?: boolean;

    //Flag that enables the use of TIAs
    useTIAs?: boolean;

    /**
     * Flag that enables using workgroups for an enterprise account
     */
    useWorkGroups?: boolean;

    /**
     * Flag that enables clickable change indicators and viewable prior versions
     */
    useEnhancedChangeIndicators?: boolean;

    /**
     * Flag that enables internal free trials
     */
    useInternalFreeTrial?: boolean;

    /**
     * Validate email addresses.  Currently used for the new user form and user creation.
     */
    validateEmailAddresses?: boolean;

    /**
     * Enable Add Bookmark button inside table menu button
     */
    addTableBookmark?: boolean;

    // used for tests
    testBooleanFeatureFlag?: boolean;
}

/**
 * Default values for feature flags.
 * These values should match those on the backend.
 * see FeatureFlags.cs#GetDefaults
 */
export const DEFAULT_FEATURE_FLAGS: Required<FeatureFlags> = {
    addTableBookmark: false,
    allowBatchPublications: false,
    allowPDFsInPublicationUpload: false,
    bypassHermes: false,
    extractTablesFromPublicationImport: false,
    i18N: false,
    includeEnhancedContentInSearch: false,
    internalizeEula: false,
    refreshOnPageChange: false,
    prependLabelToSectionTitle: false,
    showAllPublicationsPage: false,
    useOneTrustPrivacyPolicyBanner: false,
    showUnhandledXml: false,
    showVideoDemoFaq: false,
    useAzureB2CForSSO: false,
    useAzureGoLiveBanner: false,
    useAzurePostGoLiveBanner: false,
    useChangeSummaries: false,
    useDocumentViewer: false,
    useErrorBoundary: false,
    useExpandableTableOfContents: false,
    useGroupInvitationSignoutFlow: true,
    useHermesApiForTeams: false,
    useInternalFreeTrial: false,
    useMarkdownForTermsAndConditions: false,
    useOfflineFeatures: false,
    usePendo: false,
    usePlansPageStructuredData: false,
    usePublicationPreviewPageStructuredData: false,
    usePutItForwardForTopics: false,
    useReducedRendersInBookview: false,
    useSimpleTableCards: false,
    useSimpleTableStyle: false,
    useSsoSilent: false,
    useTIAs: false,
    useWorkGroups: false,
    useEnhancedChangeIndicators: false,
    validateEmailAddresses: false,
    // used only for tests and storybook for feature-flag.tsx
    testBooleanFeatureFlag: false,
};
