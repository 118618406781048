// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum RoleType {
    ENTERPRISE = 2,
    FREE_TRIAL = 3,
    INDIVIDUAL = 0,
    NON_ADMIN_AUTHORIZED_USER = 5,
    PUBLISHER = 7,
    SUPPORT = 9,
    SYS_ADMIN = 4,
    AUTHOR = 6,
    TEAM = 1,
    INTEGRATION_TESTING = 8,
    TRAINING_CONTRACT = 10,
}

// #endregion Enum

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

/**
 * Array to group admin-associated roletypes to avoid duplication across the app
 */
export const ContentAdminRoleTypes: RoleType[] = [
    RoleType.AUTHOR,
    RoleType.PUBLISHER,
    RoleType.SYS_ADMIN,
];

export const AdminRoleTypes: RoleType[] = [
    RoleType.AUTHOR,
    RoleType.PUBLISHER,
    RoleType.SUPPORT,
    RoleType.SYS_ADMIN,
];

export default RoleType;

// #endregion Exports
