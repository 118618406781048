import { Record } from "immutable";
import type User from "models/interfaces/user";
import UserRoleRecord from "models/view-models/user-role-record";
import { CollectionUtils } from "utilities/collection-utils";
import { RecordUtils } from "andculturecode-javascript-core";
import StringUtils from "utilities/string-utils";

const defaultValues: User = RecordUtils.auditableDefaultValuesFactory<User>({
    email: undefined,
    eulaAccepted: false,
    externalTopicsUpdatedOn: undefined,
    firstName: "",
    isSuperAdmin: false,
    lastName: "",
    phoneNumber: undefined,
    mobileNumber: undefined,
    userName: undefined,
    vistaId: undefined,
    webId: undefined,

    // navigation properties
    userRoles: undefined,
});

export default class UserRecord extends Record(defaultValues) implements User {
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Partial<User>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (CollectionUtils.hasValues(params.userRoles)) {
            params.userRoles = params.userRoles?.map((ur: UserRoleRecord) =>
                RecordUtils.ensureRecord(ur, UserRoleRecord)
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public getFirstAndLastName(): string {
        return `${this.firstName ?? ""} ${this.lastName ?? ""}`;
    }

    public getInitials(): string {
        if (!this.hasFirstName() || !this.hasLastName()) {
            return "";
        }

        return `${this.firstName[0] ?? ""}${
            this.lastName[0] ?? ""
        }`.toUpperCase();
    }

    public getPhoneOrMobileNumber(): string | undefined {
        return this.phoneNumber ?? this.mobileNumber;
    }

    public hasFirstName(): boolean {
        return StringUtils.hasValue(this.firstName);
    }

    public hasLastName(): boolean {
        return StringUtils.hasValue(this.lastName);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<User>} values
     */
    public with(values: Partial<User>): UserRecord {
        return new UserRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
