import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import YoutubeMetadataRecord from "models/view-models/external/youtube/youtube-metadata-record";
import VideoModal from "organisms/modals/video-modal/video-modal";
import React, { useEffect, useState } from "react";
import useModalActions from "utilities/hooks/use-modal-actions";
import YoutubeMetadataService from "utilities/services/external/youtube/youtube-metadata-service";
import StringUtils from "utilities/string-utils";
import { IconSizes } from "atoms/constants/icon-sizes";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface VideoCardProps {
    videoId?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-video-card";
const BUTTON_CLASS_NAME = `${BASE_CLASS_NAME}__button`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const VideoCard: React.FC<VideoCardProps> = (
    props: React.PropsWithChildren<VideoCardProps>
) => {
    const { children, videoId } = props;
    const { isOpen, handleClose, handleOpen } = useModalActions();

    const [metadata, setMetaData] = useState<YoutubeMetadataRecord>();

    const { get: getYoutubeMetadataApi } = YoutubeMetadataService.useGet();
    const videoIdentifierIsEmpty = StringUtils.isEmpty(videoId);
    useEffect(() => {
        if (videoIdentifierIsEmpty) {
            return;
        }

        const loadYoutubeMetadata = async () => {
            try {
                const result = await getYoutubeMetadataApi({
                    videoId: videoId!,
                });
                setMetaData(result?.resultObject);
            } catch (e) {}
        };

        loadYoutubeMetadata();
    }, [getYoutubeMetadataApi, setMetaData, videoId, videoIdentifierIsEmpty]);

    if (videoIdentifierIsEmpty) {
        return null;
    }

    return (
        <div className={BASE_CLASS_NAME}>
            <button className={BUTTON_CLASS_NAME} onClick={handleOpen}>
                <div
                    className={`${BUTTON_CLASS_NAME}__thumbnail`}
                    style={{
                        backgroundImage: `url(${metadata?.thumbnailUrl})`,
                    }}></div>
                <div className={`${BUTTON_CLASS_NAME}__footer`}>
                    <div className={`${BUTTON_CLASS_NAME}__icon`}>
                        <Icon size={IconSizes.Large} type={Icons.MediaPlay} />
                    </div>
                    <div className={`${BUTTON_CLASS_NAME}__footer__content`}>
                        <Paragraph>{children}</Paragraph>
                        {
                            // if
                            StringUtils.hasValue(metadata?.description) && (
                                <div
                                    className={`${BUTTON_CLASS_NAME}__footer__label`}>
                                    <Paragraph size={ParagraphSizes.XSmall}>
                                        {StringUtils.truncateRight(
                                            metadata?.description ?? "",
                                            100
                                        )}
                                    </Paragraph>
                                </div>
                            )
                        }
                    </div>
                </div>
            </button>
            <VideoModal
                autoplay={true}
                handleClose={handleClose}
                isOpen={isOpen}
                title={metadata?.title ?? ""}
                videoId={videoId!}
            />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default VideoCard;

// #endregion Exports
