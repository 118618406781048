import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import DashboardVideoRecord from "models/view-models/dashboard-video-record";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import InputFormField from "molecules/form-fields/input-form-field";
import Modal, { ModalProps } from "molecules/modals/modal";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { t } from "utilities/localization-utils";
import SystemSettingsService from "utilities/services/system-settings/system-settings-service";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface DashboardVideoUpdateModalProps
    extends Pick<ModalProps, "closeDialog" | "isVisible"> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const DashboardVideoUpdateModal: React.FC<DashboardVideoUpdateModalProps> = (
    props: DashboardVideoUpdateModalProps
) => {
    const { isVisible, closeDialog } = props;
    const { globalState, setGlobalState } = useGlobalState();
    const { dashboardVideo } = globalState?.getSystemSettings();
    const [record, setRecord] = useState(
        dashboardVideo ?? new DashboardVideoRecord()
    );
    const { update } = SystemSettingsService.useUpdateDashboardVideo();
    const isMobile = window.innerWidth < Breakpoints.Phone;
    const transitionEffect = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;

    const updateVideoError = t("errors-actionResource", {
        action: t("updating"),
        resource: "DashboardVideo",
    });

    useEffect(() => {
        if (dashboardVideo == null) {
            return;
        }

        setRecord(dashboardVideo);
    }, [dashboardVideo]);

    const handleSave = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();

        try {
            const updateResult = await update(record);
            setGlobalState((globalState) =>
                globalState.setSystemSettings(
                    globalState.systemSettings?.with({
                        dashboardVideo: updateResult.resultObject,
                    })
                )
            );
            closeDialog();
            ToastManager.success("Successfully updated DashboardVideo");
        } catch (error) {
            ToastManager.error(updateVideoError);
        }
    };

    return (
        <Modal
            closeDialog={closeDialog}
            isVisible={isVisible}
            label={"Update Dashboard Video"}
            transition={transitionEffect}
            type={ModalTypes.Base}>
            <div className={"c-modal__header"}>
                <h2>Update Dashboard Video</h2>
                <Button
                    cssClassName={"-modal-close -icon"}
                    onClick={closeDialog}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={"c-modal__content"} style={{ padding: 24 }}>
                <form>
                    <InputFormField
                        label={"Video Card Title"}
                        isValid={true}
                        maxLength={100}
                        onChange={(e) =>
                            setRecord(
                                record.with({ videoCardTitle: e.target.value })
                            )
                        }
                        showCharacterCount={false}
                        value={record.videoCardTitle}
                    />
                    <InputFormField
                        label={"Youtube Video Identifier"}
                        isValid={true}
                        maxLength={100}
                        onChange={(e) =>
                            setRecord(
                                record.with({ videoIdentifier: e.target.value })
                            )
                        }
                        showCharacterCount={false}
                        value={record.videoIdentifier}
                    />

                    <Button type={ButtonTypes.Submit} onClick={handleSave}>
                        Save
                    </Button>
                </form>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default DashboardVideoUpdateModal;

// #endregion Exports
