export const siteMap = {
    home: "/",
    account: {
        dashboard: "/account",
        information: "/account/information",
        subscription: "/account/subscription",
        offline: "/account/offline",
        team: "/account/team",
        workGroup: "/account/workgroup",
    },
    admin: {
        announcements: "/admin/announcements",
        changeSummaries: {
            dashboard: "/admin/change-summaries",
        },
        dashboard: "/admin",
        enhancedContent: {
            dashboard: "/admin/enhanced-content",
        },
        featureFlags: "/admin/featureflags",
        featuredPublications: {
            dashboard: "/admin/featuredpublications",
        },
        freeAccessPublications: {
            dashboard: "/admin/free-access/publications",
            jobs: "/admin/free-access/jobs",
        },
        situationalNavigation: {
            categories: {
                dashboard: "/admin/situational-navigation/categories",
                equipment: {
                    dashboard:
                        "/admin/situational-navigation/categories/equipment",
                    edit: "/admin/situational-navigation/categories/equipment/edit/:id",
                    create: "/admin/situational-navigation/categories/equipment/create",
                },
                occupancies: {
                    dashboard:
                        "/admin/situational-navigation/categories/occupancies",
                    edit: "/admin/situational-navigation/categories/occupancies/edit/:id",
                    create: "/admin/situational-navigation/categories/occupancies/create",
                },
                spaces: {
                    dashboard:
                        "/admin/situational-navigation/categories/spaces",
                    edit: "/admin/situational-navigation/categories/spaces/edit/:id",
                    create: "/admin/situational-navigation/categories/spaces/create",
                },
                systems: {
                    dashboard:
                        "/admin/situational-navigation/categories/systems",
                    edit: "/admin/situational-navigation/categories/systems/edit/:id",
                    create: "/admin/situational-navigation/categories/systems/create",
                },
            },
            dashboard: "/admin/situational-navigation",
            solutions: {
                dashboard: "/admin/situational-navigation/solutions",
                edit: "/admin/situational-navigation/solutions/edit/:id",
                create: "/admin/situational-navigation/solutions/create",
            },
            situations: {
                dashboard: "/admin/situational-navigation/situations",
                edit: "/admin/situational-navigation/situations/edit/:id",
                create: "/admin/situational-navigation/situations/create",
            },
        },
        support: {
            userDetail: "/admin/support/users/:id",
            users: "/admin/support/users",
        },
        publications: {
            batch: "/admin/publications/batch",
            dashboard: "/admin/publications",
            edit: "/admin/publications/edit/:id",
            create: "/admin/publications/create",
        },
        userManagement: {
            dashboard: "/admin/users",
        },
    },
    landing: "/landing",
    azureLogin: "/azure",
    redirectToLogin: "/redirect-to-login",
    dashboards: {
        user: "/dashboards/user",
        offline: "/dashboards/offline",
    },
    errors: {
        notFound: "/errors/not-found",
    },
    freeAccess: {
        publications: "/free-access/publications/:code/:edition",
    },
    favorites: "/favorites",
    group: {
        acceptInvite: "/group/:groupId/invitation/:token",
        joinOnDemand: "/group/:groupId/join/on-demand",
    },
    myLink: {
        dashboard: "/mylink",
        myBookmarks: "/mylink/bookmarks",
        collections: "/mylink/collections",
        collection: "/mylink/collections/:id",
    },
    publications: {
        deprecated: {
            annex: "/publications/:publicationId/annexes/:id",
            annexGroup:
                "/publications/:publicationId/annexes/:annexId/group/:id",
            article:
                "/publications/:publicationId/chapters/:chapterId/articles/:id",
            chapter: "/publications/:publicationId/chapters/:id",
            publicationMetadata: "/publications/:publicationId/metadata/:type",
            publication: "/publications/:id",
            section:
                "/publications/:publicationId/chapters/:chapterId/sections/:id",
        },
        adminPreview: {
            annex: "/preview/:publicationId/annexes/:id",
            annexGroup: "/preview/:publicationId/annexes/:annexId/group/:id",
            article: "/preview/:publicationId/chapters/:chapterId/articles/:id",
            chapter: "/preview/:publicationId/chapters/:id",
            publicationMetadata: "/preview/:publicationId/metadata/:type",
            publication: "/preview/:id",
            section: "/preview/:publicationId/chapters/:chapterId/sections/:id",
            chapterTables: "/preview/:publicationId/chapters/:chapterId/tables",
            annexTables: "/preview/:publicationId/annexes/:annexId/tables",
        },
        permalinks: {
            annex: "/publications/:code/:edition/annexes/:nfpaLabel",
            annexGroup:
                "/publications/:code/:edition/annexes/:nfpaLabel/groups/:id",
            article:
                "/publications/:code/:edition/chapters/:chapterNfpaLabel/articles/:nfpaLabel",
            chapter: "/publications/:code/:edition/chapters/:nfpaLabel",
            publication: "/publications/:code/:edition",
            publicationMetadata: "/publications/:code/:edition/metadata/:type",
            section:
                "/publications/:code/:edition/chapters/:chapterNfpaLabel/sections/:externalId",
            chapterTables:
                "/publications/:code/:edition/chapters/:chapterNfpaLabel/tables",
            annexTables:
                "/publications/:code/:edition/annexes/:annexNfpaLabel/tables",
        },
        public: {
            all: "/all-publications",
            preview: "/all-publications/:code/:edition",
        },
    },
    share: {
        base: "/share",
        section: "/share/section/:guid",
    },
    signup: {
        dashboard: "/sign-up",
        freeTrial: "/sign-up/register/trial",
        plans: "/sign-up/plans",
        upcomingContent: "/sign-up/plans#upcomingContent",
        register: "/sign-up/register",
        registerPlanSelected: "/sign-up/register/:subscriptionType",
        registerTrainingContract: "/sign-up/register/training-contract/:id",
    },
    situationalNavigation: {
        dashboard: "/situational-navigation",
        situations: "/situational-navigation/situations/:id",
        solutions: "/situational-navigation/solutions/:id",
        results: "/situational-navigation/results",
    },
    support: {
        faq: "/support",
    },
    termsAndConditions: "/terms-and-conditions",
    topics: "/topics",
    userlogins: {
        dashboard: "/userlogins",
        new: "/userlogins/new",
        get: "/userlogins/get/:subscriptionPin/:externalIdentifier?",
    },
};
