enum RoleNames {
    AUTHOR = "Author",
    ENTERPRISE = "Enterprise Subscription",
    FREE_TRIAL = "Free Trial",
    INDIVIDUAL_SUBSCRIPTION = "Individual Subscription",
    INTEGRATION_TESTING = "Integration Testing",
    NON_ADMIN_AUTHORIZED_USER = "User",
    PUBLISHER = "Publisher",
    SYS_ADMIN = "System Admin",
    TEAM_SUBSCRIPTION = "Team Subscription",
    SUPPORT = "Support",
}

export default RoleNames;
