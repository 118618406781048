import PublicationCreate from "organisms/admin/publications/publication-create";
import PublicationEditor from "organisms/admin/publications/publication-editor";
import CategoryEditor from "organisms/admin/situational-navigation/categories/category-editor";
import CategoryEditorLayout from "organisms/admin/situational-navigation/categories/category-editor-layout";
import SituationEditor from "organisms/admin/situational-navigation/situations/situation-editor";
import SolutionEditor from "organisms/admin/situational-navigation/solutions/solution-editor";
import MyBookmarksTab from "organisms/my-link/my-bookmarks/my-bookmarks-tab";
import UserCollectionsTab from "organisms/my-link/user-collections/user-collections-tab";
import AccountDashboardPage from "pages/account/account-dashboard";
import AccountInformationTabPanel from "pages/account/tabs/tab-panels/account-information-tab-panel";
import AccountOfflineTabPanel from "pages/account/tabs/tab-panels/account-offline-tab-panel";
import AccountSubscriptionTabPanel from "pages/account/tabs/tab-panels/account-subscription-tab-panel";
import TeamManagementTabPanel from "pages/account/tabs/tab-panels/team-management-tab-panel";
import AdminDashboardPage from "pages/admin/admin-dashboard";
import AnnouncementsAdminPage from "pages/admin/announcements/announcements-admin-page";
import EnhancedContentAdminDashboard from "pages/admin/publications/enhanced-content/enhanced-content-admin-page";
import PublicationsAdminDashboard from "pages/admin/publications/publications-admin-dashboard";
import CategoriesPage from "pages/admin/situational-navigation/categories-page";
import { SituationalNavigationDashboard } from "pages/admin/situational-navigation/situational-navigation-dashboard";
import SituationsPage from "pages/admin/situational-navigation/situations-page";
import SolutionsPage from "pages/admin/situational-navigation/solutions-page";
import UserManagementPage from "pages/admin/users/user-management-page";
import UserDashboardPage from "pages/dashboards/user-dashboard";
import NotFoundPage from "pages/errors/not-found";
import FeatureFlagsPage from "pages/feature-flags-page";
import AcceptGroupInvitationPage from "pages/groups/accept-group-invitation-page";
import UserCollectionPage from "pages/my-link/user-collection/user-collection-page";
import RegisterAccountPage from "pages/registration/register-account-page";
import SelectFavoritesPage from "pages/registration/select-favorites-page";
import SelectPlanPage from "pages/registration/select-plan-page";
import SelectTopicsPage from "pages/registration/select-topics-page";
import SectionShareLinkPage from "pages/share/section-share-link-page";
import SituationalNavigationPage from "pages/situational-navigation/situational-navigation-page";
import SituationalNavigationResultsPage from "pages/situational-navigation/situational-navigation-results-page";
import SituationPage from "pages/situational-navigation/situations/situation-page";
import SolutionPage from "pages/situational-navigation/solutions/solution-page";
import GetUserLoginPage from "pages/userlogins/get-user-login-page";
import NewUserLoginPage from "pages/userlogins/new-user-login-page";
import { siteMap } from "internal-sitemap";
import ApplicationLayout from "templates/application-layout";
import MyLinkLayout from "templates/my-link-layout";
import ShareLayout from "templates/share-layout";
import SignupLayout from "templates/signup-layout";
import SupportLayout from "templates/support-layout";
import UserLoginLayout from "templates/user-login-layout";
import RoleType from "utilities/enumerations/role-type";
import { RouteMap } from "utilities/interfaces/route-map";
import OfflineDashboard from "pages/dashboards/offline-dashboard";
import PublicationPreviewPage from "pages/public/publication-preview-page";
import AllPublicationsPage from "pages/all-publications/all-publications-page";
import PublicPublicationsLayout from "templates/public-publications-layout";
import SupportPage from "pages/support/support-page";
import PublicationBatch from "organisms/admin/publications/publication-batch";
import WorkGroupTabPanel from "pages/account/tabs/tab-panels/work-groups/work-groups-tab-panel";
import FreeAccessPage from "pages/free-access/free-access-page";
import JoinGroupOnDemandPage from "pages/groups/join-group-on-demand-page/join-group-on-demand-page";
import FreeAccessPublicationsAdminDashboard from "pages/admin/free-access/free-access-publications-admin-dashboard";
import FreeAccessPublicationsAdminJobManagement from "pages/admin/free-access/free-access-publications-admin-job-management";
import ChangeSummariesAdminDashboard from "pages/admin/publications/change-summaries/change-summaries-admin-page";
import AzureLoginPage from "pages/azure-login/azure-login-page";
import TermsWrapper from "pages/terms-and-conditions/terms-wrapper";
import { CoreUtils } from "./utilities/core-utils";
import { RouteUtils } from "./utilities/route-utils";
import { publicationRoutes } from "./publication-routes";
import { RouteDefinition } from "./utilities/interfaces/route-definition";
import SupportUsersPage from "./pages/admin/support/support-users-page";
import SupportUserDetailPage from "pages/admin/support/support-user-detail-page";
import SupportUsersLayout from "pages/admin/support/support-users-layout";
import TrainingContractPage from "pages/training-contract/training-contract-page";
import AzureRedirectPage from "pages/azure-login/azure-redirect-page";

/*
---------------------------------------------------------------------------------------------
Routing Table
---------------------------------------------------------------------------------------------
*/

export const routes: RouteMap = {
    /**
     * Anything that doesn't use ApplicationLayout will
     * need to be placed before the `home` route,
     * such that the <Switch> component picks up the desired
     * layout before the ApplicationLayout, since the `home`
     * route is just "/"
     */

    freeAccess: {
        authRequired: true,
        unathenticatedRedirectToAzureB2C: true,
        component: FreeAccessPage,
        exact: true,
        path: siteMap.freeAccess.publications,
        routes: {},
    },
    allPublications: {
        authRequired: false,
        component: PublicPublicationsLayout,
        exact: false,
        path: siteMap.publications.public.all,
        routes: {
            all: {
                authRequired: false,
                component: AllPublicationsPage,
                exact: true,
                path: siteMap.publications.public.all,
                routes: {},
            },
            preview: {
                authRequired: false,
                component: PublicationPreviewPage,
                exact: true,
                path: siteMap.publications.public.preview,
                routes: {},
            },
        },
    },
    redirectToLogin: {
        authRequired: false,
        component: AzureRedirectPage,
        exact: true,
        path: siteMap.redirectToLogin,
        routes: {},
    },
    azureLogin: {
        authRequired: false,
        component: AzureLoginPage,
        exact: true,
        path: siteMap.azureLogin,
        routes: {},
    },
    favorites: {
        authRequired: true,
        component: SelectFavoritesPage,
        exact: true,
        path: siteMap.favorites,
        routes: {},
    },

    groups: {
        authRequired: false,
        component: AcceptGroupInvitationPage,
        exact: true,
        path: siteMap.group.acceptInvite,
        routes: {},
    },

    joinGroupOnDemand: {
        authRequired: false,
        component: JoinGroupOnDemandPage,
        exact: true,
        path: siteMap.group.joinOnDemand,
        routes: {},
    },

    // errors
    notFound: {
        authRequired: false,
        component: NotFoundPage,
        exact: true,
        path: siteMap.errors.notFound,
        routes: {},
    },

    share: {
        authRequired: false,
        component: ShareLayout,
        skipUserConfigurationRedirects: true,
        path: siteMap.share.base,
        routes: {
            section: {
                authRequired: false,
                component: SectionShareLinkPage,
                exact: true,
                skipUserConfigurationRedirects: true,
                path: siteMap.share.section,
                routes: {},
            },
        },
    },

    signup: {
        authRequired: false,
        component: SignupLayout,
        path: siteMap.signup.dashboard,
        routes: {
            plans: {
                authRequired: false,
                component: SelectPlanPage,
                exact: true,
                path: siteMap.signup.plans,
                routes: {},
            },
            // This route remains here to support redirection from a SKU only to the :subscriptionType route
            register: {
                authRequired: false,
                exact: true,
                component: RegisterAccountPage,
                path: siteMap.signup.register,
                routes: {},
            },
            registerPlanSelected: {
                authRequired: false,
                exact: true,
                component: RegisterAccountPage,
                path: siteMap.signup.registerPlanSelected,
                routes: {},
            },
            registerTrainingContract: {
                authRequired: false,
                exact: true,
                component: TrainingContractPage,
                path: siteMap.signup.registerTrainingContract,
                routes: {},
            },
        },
    },

    support: {
        authRequired: false,
        component: SupportLayout,
        exact: true,
        path: siteMap.support.faq,
        routes: {
            faq: {
                authRequired: false,
                component: SupportPage,
                exact: true,
                path: siteMap.support.faq,
                skipUserConfigurationRedirects: true,
                routes: {},
            },
        },
    },

    // Terms and Conditions / EULA
    termsAndConditions: {
        authRequired: true,
        exact: true,
        component: TermsWrapper,
        path: siteMap.termsAndConditions,
        routes: {},
    },

    // Select topics
    topics: {
        authRequired: true,
        component: SelectTopicsPage,
        exact: true,
        path: siteMap.topics,
        routes: {},
    },

    // userlogins
    userlogins: {
        authRequired: false,
        component: UserLoginLayout,
        path: siteMap.userlogins.dashboard,
        routes: {
            new: {
                authRequired: false,
                component: NewUserLoginPage,
                path: siteMap.userlogins.new,
                routes: {},
            },
            get: {
                authRequired: false,
                exact: false,
                component: GetUserLoginPage,
                path: siteMap.userlogins.get,
                routes: {},
            },
        },
    },
    //home
    home: {
        /**
         * anything that uses ApplicationLayout can be nested under the home route, so that we can avoid
         * needing blank index pages that don't do anything just to use the layout.
         *
         * authRequired needs to be false for the top-level home route, i.e. "/", otherwise
         * it will try to check auth at "/" level, so "/userlogins/new" will try to
         * check auth, resulting in an infinite redirect loop
         */
        authRequired: false,
        component: ApplicationLayout,
        path: siteMap.home,
        routes: {
            accountDashboard: {
                authRequired: true,
                component: AccountDashboardPage,
                path: siteMap.account.dashboard,
                routes: {
                    information: {
                        authRequired: true,
                        component: AccountInformationTabPanel,
                        path: siteMap.account.information,
                        exact: true,
                        routes: {},
                    },
                    subscription: {
                        authRequired: true,
                        component: AccountSubscriptionTabPanel,
                        path: siteMap.account.subscription,
                        exact: true,
                        routes: {},
                    },
                    offline: {
                        authRequired: true,
                        component: AccountOfflineTabPanel,
                        path: siteMap.account.offline,
                        exact: true,
                        routes: {},
                    },
                    team: {
                        authRequired: true,
                        component: TeamManagementTabPanel,
                        path: siteMap.account.team,
                        exact: true,
                        routes: {},
                    },
                    workGroup: {
                        authRequired: true,
                        component: WorkGroupTabPanel,
                        path: siteMap.account.workGroup,
                        requiresRoles: [
                            RoleType.SYS_ADMIN,
                            RoleType.ENTERPRISE,
                        ],
                        exact: true,
                        routes: {},
                    },
                },
            },
            adminDashboard: {
                authRequired: true,
                component: AdminDashboardPage,
                path: siteMap.admin.dashboard,
                requiresRoles: [
                    RoleType.SYS_ADMIN,
                    RoleType.AUTHOR,
                    RoleType.PUBLISHER,
                    RoleType.SUPPORT,
                ],
                exact: true,
                routes: {},
            },
            announcementsAdmin: {
                authRequired: true,
                component: AnnouncementsAdminPage,
                exact: true,
                path: siteMap.admin.announcements,
                requiresRoles: [RoleType.SYS_ADMIN],
                routes: {},
            },
            changeSummaries: {
                authRequired: true,
                component: ChangeSummariesAdminDashboard,
                path: siteMap.admin.changeSummaries.dashboard,
                requiresRoles: [
                    RoleType.SYS_ADMIN,
                    RoleType.PUBLISHER,
                    RoleType.AUTHOR,
                ],
                exact: true,
                routes: {},
            },
            myLink: {
                authRequired: true,
                component: MyLinkLayout,
                path: siteMap.myLink.dashboard,
                exact: false,
                routes: {
                    myBookmarks: {
                        authRequired: true,
                        component: MyBookmarksTab,
                        path: siteMap.myLink.myBookmarks,
                        routes: {},
                    },
                    collections: {
                        authRequired: true,
                        component: UserCollectionsTab,
                        exact: true,
                        path: siteMap.myLink.collections,
                        routes: {},
                    },
                    collection: {
                        authRequired: true,
                        component: UserCollectionPage,
                        exact: true,
                        path: siteMap.myLink.collection,
                        routes: {},
                    },
                },
            },
            enhancedContentAdmin: {
                authRequired: true,
                component: EnhancedContentAdminDashboard,
                path: siteMap.admin.enhancedContent.dashboard,
                requiresRoles: [
                    RoleType.SYS_ADMIN,
                    RoleType.PUBLISHER,
                    RoleType.AUTHOR,
                ],
                exact: true,
                routes: {},
            },
            featureFlags: {
                authRequired: true,
                component: FeatureFlagsPage,
                path: siteMap.admin.featureFlags,
                exact: true,
                routes: {},
            },
            freeAccessPublicationsAdmin: {
                authRequired: true,
                component: FreeAccessPublicationsAdminDashboard,
                path: siteMap.admin.freeAccessPublications.dashboard,
                requiresRoles: [
                    RoleType.AUTHOR,
                    RoleType.PUBLISHER,
                    RoleType.SYS_ADMIN,
                ],
                exact: true,
                routes: {},
            },
            freeAccessJobAdmin: {
                authRequired: true,
                component: FreeAccessPublicationsAdminJobManagement,
                path: siteMap.admin.freeAccessPublications.jobs,
                requiresRoles: [
                    RoleType.AUTHOR,
                    RoleType.PUBLISHER,
                    RoleType.SYS_ADMIN,
                ],
                exact: true,
                routes: {},
            },
            publicationsAdmin: {
                authRequired: true,
                component: PublicationsAdminDashboard,
                path: siteMap.admin.publications.dashboard,
                requiresRoles: [
                    RoleType.AUTHOR,
                    RoleType.PUBLISHER,
                    RoleType.SYS_ADMIN,
                ],
                exact: false,
                routes: {
                    batch: {
                        authRequired: true,
                        component: PublicationBatch,
                        path: siteMap.admin.publications.batch,
                        requiresRoles: [
                            RoleType.SYS_ADMIN,
                            RoleType.PUBLISHER,
                            RoleType.AUTHOR,
                        ],
                        exact: true,
                        routes: {},
                    },
                    edit: {
                        authRequired: true,
                        component: PublicationEditor,
                        path: siteMap.admin.publications.edit,
                        requiresRoles: [
                            RoleType.AUTHOR,
                            RoleType.PUBLISHER,
                            RoleType.SYS_ADMIN,
                        ],
                        exact: true,
                        routes: {},
                    },
                    create: {
                        authRequired: true,
                        component: PublicationCreate,
                        path: siteMap.admin.publications.create,
                        requiresRoles: [
                            RoleType.SYS_ADMIN,
                            RoleType.PUBLISHER,
                            RoleType.AUTHOR,
                        ],
                        exact: true,
                        routes: {},
                    },
                },
            },
            situationalNavigationAdmin: {
                authRequired: true,
                component: SituationalNavigationDashboard,
                path: siteMap.admin.situationalNavigation.dashboard,
                requiresRoles: [
                    RoleType.SYS_ADMIN,
                    RoleType.AUTHOR,
                    RoleType.PUBLISHER,
                ],
                exact: false,
                routes: {
                    categories: {
                        authRequired: true,
                        component: CategoriesPage,
                        path: siteMap.admin.situationalNavigation.categories
                            .dashboard,
                        requiresRoles: [RoleType.SYS_ADMIN, RoleType.PUBLISHER],
                        routes: {
                            equipment: {
                                authRequired: true,
                                component: CategoryEditorLayout,
                                path: siteMap.admin.situationalNavigation
                                    .categories.equipment.dashboard,
                                requiresRoles: [
                                    RoleType.SYS_ADMIN,
                                    RoleType.PUBLISHER,
                                ],
                                routes: {
                                    edit: {
                                        authRequired: true,
                                        component: CategoryEditor,
                                        path: siteMap.admin
                                            .situationalNavigation.categories
                                            .equipment.edit,
                                        requiresRoles: [
                                            RoleType.SYS_ADMIN,
                                            RoleType.PUBLISHER,
                                        ],
                                        routes: {},
                                    },
                                    create: {
                                        authRequired: true,
                                        component: CategoryEditor,
                                        path: siteMap.admin
                                            .situationalNavigation.categories
                                            .equipment.create,
                                        requiresRoles: [
                                            RoleType.SYS_ADMIN,
                                            RoleType.PUBLISHER,
                                        ],
                                        routes: {},
                                    },
                                },
                            },
                            occupancies: {
                                authRequired: true,
                                component: CategoryEditorLayout,
                                path: siteMap.admin.situationalNavigation
                                    .categories.occupancies.dashboard,
                                requiresRoles: [
                                    RoleType.SYS_ADMIN,
                                    RoleType.PUBLISHER,
                                ],
                                routes: {
                                    edit: {
                                        authRequired: true,
                                        component: CategoryEditor,
                                        path: siteMap.admin
                                            .situationalNavigation.categories
                                            .occupancies.edit,
                                        requiresRoles: [
                                            RoleType.SYS_ADMIN,
                                            RoleType.PUBLISHER,
                                        ],
                                        routes: {},
                                    },
                                    create: {
                                        authRequired: true,
                                        component: CategoryEditor,
                                        path: siteMap.admin
                                            .situationalNavigation.categories
                                            .occupancies.create,
                                        requiresRoles: [
                                            RoleType.SYS_ADMIN,
                                            RoleType.PUBLISHER,
                                        ],
                                        routes: {},
                                    },
                                },
                            },
                            spaces: {
                                authRequired: true,
                                component: CategoryEditorLayout,
                                path: siteMap.admin.situationalNavigation
                                    .categories.spaces.dashboard,
                                requiresRoles: [
                                    RoleType.SYS_ADMIN,
                                    RoleType.PUBLISHER,
                                ],
                                routes: {
                                    edit: {
                                        authRequired: true,
                                        component: CategoryEditor,
                                        path: siteMap.admin
                                            .situationalNavigation.categories
                                            .spaces.edit,
                                        requiresRoles: [
                                            RoleType.SYS_ADMIN,
                                            RoleType.PUBLISHER,
                                        ],
                                        routes: {},
                                    },
                                    create: {
                                        authRequired: true,
                                        component: CategoryEditor,
                                        path: siteMap.admin
                                            .situationalNavigation.categories
                                            .spaces.create,
                                        requiresRoles: [
                                            RoleType.SYS_ADMIN,
                                            RoleType.PUBLISHER,
                                        ],
                                        routes: {},
                                    },
                                },
                            },
                            systems: {
                                authRequired: true,
                                component: CategoryEditorLayout,
                                path: siteMap.admin.situationalNavigation
                                    .categories.systems.dashboard,
                                requiresRoles: [
                                    RoleType.SYS_ADMIN,
                                    RoleType.PUBLISHER,
                                ],
                                routes: {
                                    edit: {
                                        authRequired: true,
                                        component: CategoryEditor,
                                        path: siteMap.admin
                                            .situationalNavigation.categories
                                            .systems.edit,
                                        requiresRoles: [
                                            RoleType.SYS_ADMIN,
                                            RoleType.PUBLISHER,
                                        ],
                                        routes: {},
                                    },
                                    create: {
                                        authRequired: true,
                                        component: CategoryEditor,
                                        path: siteMap.admin
                                            .situationalNavigation.categories
                                            .systems.create,
                                        requiresRoles: [
                                            RoleType.SYS_ADMIN,
                                            RoleType.PUBLISHER,
                                        ],
                                        routes: {},
                                    },
                                },
                            },
                        },
                    },
                    situations: {
                        authRequired: true,
                        component: SituationsPage,
                        path: siteMap.admin.situationalNavigation.situations
                            .dashboard,
                        requiresRoles: [
                            RoleType.SYS_ADMIN,
                            RoleType.AUTHOR,
                            RoleType.PUBLISHER,
                        ],
                        exact: false,
                        routes: {
                            edit: {
                                authRequired: true,
                                component: SituationEditor,
                                path: siteMap.admin.situationalNavigation
                                    .situations.edit,
                                requiresRoles: [
                                    RoleType.SYS_ADMIN,
                                    RoleType.AUTHOR,
                                    RoleType.PUBLISHER,
                                ],
                                routes: {},
                            },
                            create: {
                                authRequired: true,
                                component: SituationEditor,
                                path: siteMap.admin.situationalNavigation
                                    .situations.create,
                                requiresRoles: [
                                    RoleType.SYS_ADMIN,
                                    RoleType.AUTHOR,
                                    RoleType.PUBLISHER,
                                ],
                                routes: {},
                            },
                        },
                    },
                    solutions: {
                        authRequired: true,
                        component: SolutionsPage,
                        path: siteMap.admin.situationalNavigation.solutions
                            .dashboard,
                        requiresRoles: [
                            RoleType.SYS_ADMIN,
                            RoleType.AUTHOR,
                            RoleType.PUBLISHER,
                        ],
                        routes: {
                            edit: {
                                authRequired: true,
                                component: SolutionEditor,
                                path: siteMap.admin.situationalNavigation
                                    .solutions.edit,
                                requiresRoles: [
                                    RoleType.SYS_ADMIN,
                                    RoleType.AUTHOR,
                                    RoleType.PUBLISHER,
                                ],
                                routes: {},
                            },
                            create: {
                                authRequired: true,
                                component: SolutionEditor,
                                path: siteMap.admin.situationalNavigation
                                    .solutions.create,
                                requiresRoles: [
                                    RoleType.SYS_ADMIN,
                                    RoleType.AUTHOR,
                                    RoleType.PUBLISHER,
                                ],
                                routes: {},
                            },
                        },
                    },
                },
            },
            userManagementAdmin: {
                authRequired: true,
                component: UserManagementPage,
                exact: true,
                path: siteMap.admin.userManagement.dashboard,
                requiresRoles: [RoleType.SYS_ADMIN],
                routes: {},
            },
            // user dashboard
            user: {
                authRequired: true,
                component: UserDashboardPage,
                exact: true,
                path: siteMap.dashboards.user,
                routes: {},
            },
            offline: {
                authRequired: true,
                component: OfflineDashboard,
                exact: true,
                path: siteMap.dashboards.offline,
                routes: {},
            },
            situationalNavigation: {
                authRequired: true,
                component: SituationalNavigationPage,
                path: siteMap.situationalNavigation.dashboard,
                routes: {
                    situation: {
                        authRequired: true,
                        component: SituationPage,
                        exact: true,
                        path: siteMap.situationalNavigation.situations,
                        routes: {},
                    },
                    solution: {
                        authRequired: true,
                        component: SolutionPage,
                        exact: true,
                        path: siteMap.situationalNavigation.solutions,
                        routes: {},
                    },
                    solutionResults: {
                        authRequired: true,
                        component: SituationalNavigationResultsPage,
                        exact: true,
                        path: siteMap.situationalNavigation.results,
                        routes: {},
                    },
                },
            },
            supportAdmin: {
                authRequired: true,
                component: SupportUsersLayout,
                path: siteMap.admin.support.users,
                requiresRoles: [RoleType.SYS_ADMIN, RoleType.SUPPORT],
                exact: false,
                routes: {
                    supportAdmin: {
                        authRequired: true,
                        component: SupportUsersPage,
                        path: siteMap.admin.support.users,
                        requiresRoles: [RoleType.SYS_ADMIN, RoleType.SUPPORT],
                        exact: true,
                        routes: {},
                    },
                    supportAdminUserDetail: {
                        authRequired: true,
                        component: SupportUserDetailPage,
                        path: siteMap.admin.support.userDetail,
                        requiresRoles: [RoleType.SYS_ADMIN, RoleType.SUPPORT],
                        exact: true,
                        routes: {},
                    },
                },
            },
        },
    },
};

const appRoutes = RouteUtils.join(routes, publicationRoutes, "home");
export const routeArray = CoreUtils.objectToArray(appRoutes).map(
    (route: RouteDefinition) => RouteUtils.withCulture<RouteDefinition>(route)
);
